<template>
  <div
    class="hitbox"
    :class="[target.type]"
    :style="styles"
    :data-x="hitbox.left"
    :data-y="hitbox.top"
  >
    <button type="button" @click.stop.prevent="destroy()">&times;</button>

    <div class="target-info">
      <img :src="target.image_url" v-if="target.image_url" />

      <div class="detail">
        <h6 v-text="target.name"></h6>
        <ul>
          <li><b>Type:</b> {{ target.type }}</li>
          <li v-if="target.brand"><b>Brand:</b> {{ target.brand }}</li>
          <li v-if="target.upc_13">
            <b>UPC 13:</b> <code>{{ target.upc_13 }}</code>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_ZINDEX } from '../constants';
import interact from 'interact.js';
import { mapMutations } from 'vuex';

export default {
  props: {
    hitbox: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.$nextTick(() =>
      interact(this.$el)
        .draggable(true)
        .resizable({
          edges: { left: true, right: true, bottom: true, top: true },
        })
        .on('dragmove', e =>
          this.updateHitbox({
            cid: this.hitbox.cid,
            attributes: {
              left: this.hitbox.left + Number(e.dx),
              top: this.hitbox.top + Number(e.dy),
            },
          })
        )
        .on('resizemove', e =>
          this.updateHitbox({
            cid: this.hitbox.cid,
            attributes: {
              left: this.hitbox.left + Number(e.deltaRect.left),
              top: this.hitbox.top + Number(e.deltaRect.top),
              width: e.rect.width,
              height: e.rect.height,
            },
          })
        )
    );
  },

  computed: {
    styles() {
      const styles = ['top', 'left', 'width', 'height']
        .map(p => ({ [p]: `${this.hitbox[p]}px` }))
        .reduce((c, o) => ({ ...c, ...o }), {});

      styles.zIndex = BASE_ZINDEX;

      return styles;
    },

    target() {
      return this.hitbox.target;
    },
  },

  methods: {
    ...mapMutations(['removeHitbox', 'updateHitbox']),

    destroy() {
      if (!confirm('Are you sure you want to delete this click box?')) {
        return;
      }

      this.removeHitbox({ hitbox: this.hitbox });
    },
  },
};
</script>
