<template>
  <div class="facet facet--single facet--required">
    <label>Quantity</label>
    <div class="dropdown-box" :class="{ selecting }">
      <div class="contents" v-on-clickaway="close">
        <div :class="classes" @click="selecting = !selecting">
          {{ selected }}
        </div>

        <ul class="options">
          <li
            v-for="quantity in quantities"
            @click="select(quantity)"
            v-text="quantity.value"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { quantitiesForProduct } from 'lib/stubs';
import ActsAsDropdown from 'mixins/ActsAsDropdown';
import { mapState, mapMutations } from 'vuex';

export default {
  mixins: [ActsAsDropdown],

  watch: {
    product: 'setDefaultOrClear',
  },

  mounted() {
    this.$nextTick(() => {
      this.setQuantity({ quantity: this.quantities.find(q => q.default) });
    });
  },

  destroyed() {
    this.setQuantity();
  },

  computed: {
    ...mapState(['quantity', 'product']),

    selected() {
      return this.quantity ? this.quantity.value : 'Select';
    },

    quantities() {
      return quantitiesForProduct(this.product);
    },
  },

  methods: {
    ...mapMutations(['setQuantity']),

    select(quantity) {
      this.setQuantity({ quantity });
      this.selecting = false;
    },
  },
};
</script>
