<template>
  <div class="target-builder">
    <div class="field">
      <label class="control-label" for="department_id">Department</label>
      <select
        class="form-control selectize"
        id="department_id"
        name="department_id"
      >
        <option value=""></option>
        <option
          v-for="department in departments"
          :value="department.id"
          :key="department.id"
          :selected="department.id === departmentId"
          v-text="department.name"
        ></option>
      </select>
    </div>

    <template v-if="departmentId">
      <div class="form-group">
        <label class="control-label">Target Type</label>
        <div class="radio">
          <label>
            <input
              type="radio"
              name="target_type"
              value="category"
              v-model="targetType"
            />
            Category
          </label>
          <p class="help-block">
            The pseudo department will link to a category.
          </p>
        </div>
        <div class="radio">
          <label>
            <input
              type="radio"
              name="target_type"
              value="product"
              v-model="targetType"
            />
            Product
          </label>
          <p class="help-block">
            The pseudo department will link to a specific product.
          </p>
        </div>
      </div>

      <component
        :is="`target-${targetType}`"
        :target-id="targetId"
        :store-id="storeId"
        :department-id="departmentId"
      ></component>
    </template>
  </div>
</template>

<script>
import TargetCategory from './TargetCategory';
import TargetProduct from './TargetProduct';
import { selectize, fetchInjection } from 'lib/helpers';

export default {
  props: {
    storeId: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$selectize = $(this.$el.querySelector('select')).selectize();

      this.$selectize.on('change', id => {
        this.departmentId = Number(this.$selectize[0].selectize.getValue());
      });
    });
  },

  watch: {
    targetType() {
      this.targetId = null;
    },
  },

  data() {
    return {
      loading: false,
      ...fetchInjection('pseudo'),
      ...fetchInjection('form_support'),
    };
  },

  components: {
    TargetProduct,
    TargetCategory,
  },
};
</script>

<style lang="scss" scoped></style>
