<template>
  <div v-if="isEditing && isOriginal && isImported">
    <hr />

    <div v-show="!expanded">
      <h3>Bulk Update</h3>

      <p>
        Need to make these same changes to products with a matching UPC 13 at
        other stores?
      </p>
      <p>
        <a
          href="#"
          @click.stop.prevent="expanded = true"
          class="btn btn-primary btn-sm"
        >
          <b>Yes</b>, Show the Bulk Updater
        </a>
      </p>
    </div>

    <div class="panel panel-primary" v-show="expanded">
      <div class="panel-heading">
        <button @click.stop.prevent="expanded = false" class="close">
          &times;
        </button>
        <h3 class="panel-title">Bulk Updater</h3>
      </div>
      <div class="panel-body">
        <p class="help-block">
          Values for the selected items below will be applied to selected
          stores/groups with a matching UPC 13 when this product is saved.
        </p>

        <div class="form-group">
          <label class="control-label"> Items to Update </label>

          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[name]" value="1" /> Name
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[images]" value="1" />
                  Images
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[description]" value="1" />
                  Description
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[facets]" value="1" />
                  Product Attributes
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="align[unit_of_measure]"
                    value="1"
                  />
                  Unit of Measure
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[suggestions]" value="1" />
                  Suggested Products
                </label>
              </div>
            </div>

            <div class="col-sm-6 col-xs-12">
              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[keywords]" value="1" />
                  Keywords
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[sticky]" value="1" /> Push
                  to Top of Listings
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[for_sale]" value="1" />
                  Available for Purchase
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="align[accepts_customer_photo]"
                    value="1"
                  />
                  Accepts Customer Photo
                </label>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" name="align[categories]" value="1" />
                  Categories
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <div class="row">
                <div class="col-xs-7">
                  <label class="control-label">Stores/Groups to Update</label>
                </div>
                <div class="col-xs-5">
                  <input
                    type="search"
                    class="form-control input-sm js-tree-search tree-search pull-right"
                    placeholder="Search tree..."
                  />
                </div>
              </div>

              <div class="tree stores-groups js-tree"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel-footer">
        <span class="label label-danger">WARNING</span> Mistakes using this tool
        are not easily undone.
      </div>
    </div>
  </div>
</template>

<script>
import { has } from 'lodash';
import { mapGetters } from 'vuex';
import AssociationTree from 'lib/Trees/AssociationTree';

// The sub-panel for aligning specific attributes from this product to matching
// products (by UPC13) at other stores/groups which the user has management access at.
//
export default {
  mounted() {
    this.$nextTick(() => {
      if (!has(WFC, 'association_tree')) {
        // the bulk updater is only available when editing
        return;
      }

      new AssociationTree('.js-tree.stores-groups', WFC.association_tree, {
        inputFormat: 'align[{type}][]',
      }).render();
    });
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    ...mapGetters(['isOriginal', 'isEditing', 'isImported']),
  },
};
</script>
