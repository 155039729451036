<template>
  <div>
    <div class="form-group">
      <div class="radio">
        <label>
          <input
            type="radio"
            name="target_type"
            value="application"
            v-model="target.type"
          />
          Application
        </label>

        <p class="help-block">The user will be taken to the mobile app</p>
      </div>

      <div class="radio">
        <label>
          <input
            type="radio"
            name="target_type"
            value="department"
            v-model="target.type"
          />
          Department
        </label>
        <p class="help-block">
          The user will be taken to a specific department within the mobile app
        </p>
      </div>

      <div class="radio">
        <label>
          <input
            type="radio"
            name="target_type"
            value="product"
            v-model="target.type"
          />
          Product
        </label>
        <p class="help-block">
          The user will be taken to a specific product within the mobile app
        </p>
      </div>
    </div>

    <!--
      When the admin is trying to make the push notification click through to a
      department or product, this selectize list of unique department names across
      all stores will be presented to them.

      The field naming for the department is dynamic.

       - if the department is the target itself, the fields will be 'target_type' and 'target_value'
       - otherwise this section being visible means the department must be selected
         to provided _context_ for the target. The fields will be 'target_context_type'
         and 'target_context_value'
    -->

    <div v-if="isTargeting('department', 'product')">
      <input
        type="hidden"
        :name="
          isTargeting('department') ? 'target_type' : 'target_context_type'
        "
        value="department"
      />
      <label class="control-label">Which Department?</label>
      <select
        class="form-control selectize"
        :name="
          isTargeting('department') ? 'target_value' : 'target_context_value'
        "
        :data-selected="
          isTargeting('department') ? target.value : context.value
        "
      >
        <option value=""></option>
        <option
          v-for="department in departments"
          :key="department"
          :value="department"
          v-text="department"
        ></option>
      </select>
      <p class="help-block">
        The mobile app will check for the existence of an active department with
        this name at the user's home store,
        <span v-if="isTargeting('department')"
          >taking them to it if present.</span
        >
        <span v-else-if="isTargeting('product')"
          >using it to search for a product with the criteria below.</span
        >
      </p>
    </div>

    <!--
      When the admin is trying to make the push notification click through to a
      product, this form field will be displayed for them to enter detail about
      the product to lookup
    -->

    <div class="form-group" v-if="isTargeting('product')">
      <label class="control-label">Which Product?</label>
      <input class="form-control" name="target_value" :value="target.value" />
      <p class="help-block">
        Enter an item number, UPC13, keyword, or product name. The mobile app
        will use this value to search for a product at the selected department,
        taking the user to the first result returned.
      </p>
    </div>
  </div>
</template>

<script>
import { selectize } from 'lib/helpers';

export default {
  props: {
    targetType: {
      required: true,
      type: String,
      validate: value =>
        ['application', 'department', 'product'].includes(value),
    },
    targetValue: {
      required: false,
    },
    contextValue: {
      required: false,
    },
    departments: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      target: {
        type: this.targetType,
        value: this.targetValue,
      },
      context: {
        // context type is currently not a consideration here, since the only
        // target requiring context is a product (requiring a department), and
        // that context type is hard-coded in the template above
        value: this.contextValue,
      },
    };
  },

  watch: {
    // whenever the target type changes, the target value should be cleared
    'target.type'(_, oldValue) {
      this.target.value = null;

      if (oldValue === 'application') {
        // re-init the selectize plugin around the department select whenever it appears
        this.$nextTick(selectize);
      }
    },
  },

  methods: {
    isTargeting(...list) {
      return list.includes(this.target.type);
    },
  },
};
</script>
