<template>
  <div>
    <h2>Current Sync Status</h2>

    <div class="alert alert-danger" v-if="connectionIssue">
      There is a problem fetching the current sync status.
    </div>

    <div
      class="alert"
      :class="flashMessage.type === 'error' ? 'alert-danger' : 'alert-success'"
      v-else-if="flashMessage"
      v-text="flashMessage.copy"
    ></div>

    <table class="table">
      <tbody>
        <tr>
          <th>Product Sync Queue Status</th>
          <td>
            <span
              class="label"
              :class="workerStatusClass"
              v-text="stats.status"
            ></span>
          </td>
        </tr>
        <tr>
          <th>Queued Products</th>
          <td v-text="stats.products"></td>
        </tr>
        <tr>
          <th>Queued Images</th>
          <td v-text="stats.images"></td>
        </tr>
      </tbody>
    </table>

    <div class="alert alert-warning" v-if="workerAppearsStuck">
      The unchanging number of queued products above suggests the product sync
      queue may be stuck. Consider restarting it below
    </div>

    <div v-if="!workerStatusCannotBeDetermined">
      <hr />

      <h4>Queue Control</h4>

      <p>
        Use the buttons below to issue commands against the product sync queue
        responsible for processing daily product syncs.
      </p>

      <div v-if="workerIsRunning" class="queue-command">
        <button
          :disabled="commandIsProcessing"
          class="btn btn-sm btn-info"
          @click.stop.prevent="issueCommand('pause')"
        >
          Pause Product Sync Queue
        </button>
        <p class="help-block">
          Temporarily stop processing products in the sync queue.
        </p>
      </div>

      <div v-if="workerIsStopped" class="queue-command">
        <button
          :disabled="commandIsProcessing"
          class="btn btn-sm btn-info"
          @click.stop.prevent="issueCommand('resume')"
        >
          Resume Product Sync Queue
        </button>
        <p class="help-block">Begin processing the product sync queue again.</p>
      </div>

      <div v-if="workerIsRunning" class="queue-command">
        <button
          :disabled="commandIsProcessing"
          class="btn btn-sm btn-info"
          @click.stop.prevent="issueCommand('restart')"
        >
          Restart Product Sync Queue
        </button>
        <p class="help-block">
          Kill the existing, running product sync queue process, replacing it
          with a new one.
          <b>This is often useful in fixing a &ldquo;stuck&rdquo; queue.</b>
        </p>
      </div>

      <div v-if="hasItemsToProcess" class="queue-command">
        <button
          :disabled="commandIsProcessing"
          class="btn btn-sm btn-info"
          @click.stop.prevent="issueCommand('clear')"
        >
          Clear Product Sync Queue
        </button>
        <p class="help-block">Immediately empty the product sync queue.</p>
      </div>

      <div>
        <hr />
        <h4>Start a New Product Sync</h4>

        <p>
          Use the button below to begin a new product sync. Existing products in
          the sync queue will be processed before a new import issued here
          begins.
        </p>

        <div class="checkbox">
          <label>
            <input
              :disabled="commandIsProcessing"
              type="checkbox"
              v-model="syncShouldForceAPILookups"
              value="1"
            />
            Force Mi9 API lookups for product name, brand, and descriptions
          </label>
        </div>

        <button
          :disabled="commandIsProcessing"
          class="btn btn-sm btn-info"
          @click.stop.prevent="startImport()"
        >
          Begin Product Sync
        </button>

        <p class="help-block">
          <small
            ><b>Note:</b> Forcing API lookups for all products significantly
            extends the sync's running time.</small
          >
        </p>
      </div>
    </div>
    <p v-else>
      <i>
        The status of product sync queue could not be determined. This page will
        automatically update as soon as more accurate status information is
        received.
      </i>
    </p>
  </div>
</template>

<script>
import { head, uniq } from 'lodash';
import { http } from 'lib/helpers';
import axios from 'axios';

const FLASH_TIMEOUT = 5000;
const UPDATE_SECONDS = 2.5;
const STUCK_UPDATE_SECONDS = 7;
const HISTORY_KEEP = 3;
const STUCK_COUNT_THRESHOLD = 15;
const STATUS_INDETERMINATE = 'indeterminate';

const looksStuck = history =>
  history.length === HISTORY_KEEP &&
  uniq(history).length === 1 &&
  head(history) >= STUCK_COUNT_THRESHOLD;

export default {
  el: '.js-queue-management',

  created() {
    this.$nextTick(() => {
      this.fetchStats();
      this.setupFetchInterval();
    });
  },

  watch: {
    productCountHasRemainedUnchanged() {
      this.setupFetchInterval(
        looksStuck(this.historicalProductCounts)
          ? STUCK_UPDATE_SECONDS
          : UPDATE_SECONDS
      );
    },

    /**
     * When the worker is found to be working again, restart the history tracking
     * for the # of products in the queue.
     */
    workerIsRunning() {
      this.historicalProductCounts.splice(0);
    },

    historicalProductCounts(history) {
      this.productCountHasRemainedUnchanged = looksStuck(history);
    },
  },

  data() {
    return {
      productCountHasRemainedUnchanged: false,
      historicalProductCounts: [],
      loading: true,
      commandBeingIssued: null,
      connectionIssue: false,
      syncShouldForceAPILookups: false,
      flashMessage: null,
      stats: {
        products: 0,
        images: 0,
        status: STATUS_INDETERMINATE,
      },
    };
  },

  computed: {
    workerAppearsStuck() {
      if (!this.workerIsRunning) {
        // the worker is disabled
        return false;
      }

      if (this.stats.products <= 0) {
        // no products are in the queue
        return false;
      }

      // the change history has suggested the # of products in the queue has remained
      // unchanged, so the worker may actually be stuck
      return this.productCountHasRemainedUnchanged;
    },

    workerIsStopped() {
      return ['stopped', 'stopping', 'exited'].includes(this.status);
    },

    workerIsRunning() {
      return ['running', 'starting'].includes(this.status);
    },

    status() {
      return (this.stats.status || STATUS_INDETERMINATE).toLowerCase();
    },

    workerStatusCannotBeDetermined() {
      return this.status === STATUS_INDETERMINATE;
    },

    hasItemsToProcess() {
      return this.stats.products > 0 || this.stats.images > 0;
    },

    workerStatusClass() {
      switch ((this.stats.status || '').toLowerCase()) {
        case 'running':
        case 'starting':
          return 'label-success';
        case 'stopped':
        case 'stopping':
        case 'exited':
        case 'fatal':
        case 'backoff':
          return 'label-danger';
        case STATUS_INDETERMINATE:
        case 'unknown':
        default:
          return 'label-warning';
      }
    },

    commandIsProcessing() {
      return this.commandBeingIssued !== null;
    },
  },

  methods: {
    setupFetchInterval(delay = UPDATE_SECONDS) {
      clearInterval(this.$fetchInterval);

      this.$fetchInterval = setInterval(() => this.fetchStats(), delay * 1000);
    },

    async fetchStats() {
      this.loading = true;

      try {
        const {
          data: { products = 0, images = 0, status },
        } = await http.get('/admin/sync/status');

        this.stats = { products, images, status };
        this.connectionIssue = false;

        this.trackCount(products);
      } catch (error) {
        this.connectionIssue = true;
      } finally {
        this.loading = false;
      }
    },

    trackCount(count) {
      this.historicalProductCounts.unshift(count);
      this.historicalProductCounts.splice(HISTORY_KEEP);
    },

    async issueCommand(command, data = {}) {
      this.loading = true;
      this.commandBeingIssued = command;

      try {
        await axios.post(`/admin/sync/${command}`, data);
        await this.fetchStats();
        this.setSuccess(`The ${command} command was successfully issued.`);
      } catch (error) {
        this.loading = false;
        this.setError(`There was an problem issuing the ${command} command.`);
      } finally {
        this.commandBeingIssued = null;
      }
    },

    setError(copy) {
      this.setFlashMessage(copy, 'error');
    },

    setSuccess(copy) {
      this.setFlashMessage(copy);
    },

    setFlashMessage(copy, type = 'success') {
      if (this.$flashTimeout) {
        clearTimeout(this.$flashTimeout);
      }

      this.flashMessage = { copy, type };
      this.$flashTimeout = setTimeout(
        () => (this.flashMessage = null),
        FLASH_TIMEOUT
      );
    },

    startImport() {
      this.issueCommand('start', {
        force_api_lookups: this.syncShouldForceAPILookups,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.issuing-command {
  display: flex;
  flex-flow: row nowrap;

  svg {
    max-height: 1rem;
    margin-right: 0.25rem;
  }
}

.queue-command + .queue-command {
  margin-top: 2.5rem;
}
</style>
