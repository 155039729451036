<template>
  <tbody>
    <tr>
      <td v-text="facet.name"></td>
      <td>
        <input
          type="hidden"
          :name="`facet_groups[${group.cid}][facets][${cid}][id]`"
          :value="facet.id"
        />
        <input
          type="hidden"
          :name="`facet_groups[${group.cid}][facets][${cid}][weight]`"
          :value="facet.weight"
        />
        <span
          class="trigger-toggle"
          v-if="!showTriggers && canShowTriggers && isOriginal"
          @click="expandTriggers = true"
          >Show Conditionally</span
        >
        <span class="trigger-help" v-if="facet.required"
          >Required attributes cannot be shown conditionally</span
        >
      </td>
      <td>
        <button
          v-if="isOriginal"
          type="button"
          class="btn btn-danger btn-xs pull-right"
          @click="remove()"
        >
          Remove
        </button>
      </td>
    </tr>

    <tr v-if="showTriggers">
      <td colspan="3">
        <table class="table table-sm table-condensed" v-if="triggers.length">
          <thead>
            <tr>
              <th>Show When</th>
              <th>Equals</th>
            </tr>
          </thead>
          <tbody>
            <tr
              is="facet-trigger"
              v-for="trigger in triggers"
              :trigger="trigger"
              :key="`${trigger.facet_id}-${trigger.choice_id}`"
            ></tr>
          </tbody>
        </table>

        <add-facet-trigger
          :facet="facet"
          :group="group"
          v-if="availableChoicesExist && isOriginal"
        ></add-facet-trigger>
      </td>
    </tr>
  </tbody>
</template>

<script>
import FacetTrigger from './FacetTrigger';
import AddFacetTrigger from './AddFacetTrigger';
import { mapGetters } from 'vuex';
import { token } from 'lib/helpers';

export default {
  props: {
    facet: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expandTriggers: false,
      cid: token(),
    };
  },

  computed: {
    ...mapGetters(['allFacets', 'isOriginal']),

    // triggers on the group that will make this facet visible
    triggers() {
      return this.group.triggers.filter(t => t.facet_id === this.facet.id);
    },

    canShowTriggers() {
      return this.group.facets.length > 1 && !this.facet.required;
    },

    showTriggers() {
      return this.expandTriggers || this.triggers.length > 0;
    },

    availableChoicesExist() {
      // list of choice ids on triggers for this facet
      const usedChoices = this.group.triggers
        .filter(t => t.facet_id === this.facet.id)
        .map(t => t.choice_id);

      // list of ALL choice ids for any other faceets in the group
      const allChoices = this.group.facets
        .filter(({ id }) => id !== this.facet.id) // get the ids of other facets in the group
        .map(({ id }) => this.allFacets.find(f => f.id === id)) // convert them to the actual facet objects
        .reduce((acc, f) => [...acc, ...f.choices.map(c => c.id)], []); // build up a list of all choices on those facets

      return allChoices.length > usedChoices.length;
    },
  },

  methods: {
    remove() {
      this.$store.dispatch('removeFacetFromGroup', { facetId: this.facet.id });
    },
  },

  components: {
    FacetTrigger,
    AddFacetTrigger,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../_variables';

.trigger-help {
  font-size: 1.1rem;
  text-align: center;
  color: #737373;
}

.trigger-toggle {
  font-size: 1.1rem;
  color: $brand-success;
  cursor: pointer;

  &:hover {
    color: darken($brand-success, 5%);
  }
}
</style>
