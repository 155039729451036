<template>
  <div>
    <p class="loading" v-if="loading">Loading...</p>

    <product-search
      v-else-if="!product"
      origin-type="store"
      :origin-id="storeId"
      :query-params="{ department: departmentId }"
      v-on:error="applyError"
      v-on:searching="applyError"
      v-on:selected="applyProduct"
    ></product-search>

    <div v-else>
      <table class="table">
        <tbody>
          <selected-product
            :product="product"
            origin-type="store"
            :origin-id="storeId"
            v-on:remove="reset()"
          >
          </selected-product>
        </tbody>
      </table>

      <form action="/admin/items" method="post" @submit="ensureValid">
        <slot name="inputs"></slot>

        <div class="facets">
          <quantity-selector></quantity-selector>

          <component
            v-for="facet in facets"
            :is="componentFor(facet)"
            :key="facet.id"
            :facet="facet"
          >
          </component>
        </div>

        <div class="form-group">
          <textarea
            class="form-control"
            rows="3"
            name="notes"
            placeholder="Item Notes"
          ></textarea>
        </div>

        <input
          type="hidden"
          name="quantity"
          :value="quantity.id"
          v-if="quantity"
        />
        <input
          type="hidden"
          name="product_id"
          :value="product.id"
          v-if="product"
        />

        <template v-for="(preference, index) in preferences">
          <input
            type="hidden"
            :name="`preferences[${index}][facet_id]`"
            :key="`preferencce-${preference.facet}-${preference.choice}-facet`"
            :value="preference.facet"
          />
          <input
            type="hidden"
            :name="`preferences[${index}][choice_id]`"
            :key="`preferencce-${preference.facet}-${preference.choice}-choice`"
            :value="preference.choice"
          />
        </template>

        <button type="submit" class="btn btn-success" :disabled="!isValid">
          Add Item
        </button>
      </form>
    </div>

    <div class="alert alert-danger" v-if="error" v-html="error"></div>
  </div>
</template>

<script>
import { http } from 'lib/helpers';
import ProductSearch from 'components/ProductSearch/Search';
import SelectedProduct from 'components/ProductSearch/SelectedProduct';
import store from './store';
import { mapMutations, mapState, mapGetters } from 'vuex';
import QuantitySelector from './QuantitySelector';
import SingleFacet from './SingleFacet';
import MultiFacet from './MultiFacet';

export default {
  store,

  props: {
    storeId: {
      type: Number,
      required: true,
    },
    departmentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['facets']),
    ...mapState(['error', 'product', 'quantity', 'choices']),
    ...mapGetters(['isValid']),

    preferences() {
      return Object.keys(this.choices).reduce(
        (acc, facet) => [
          ...acc,
          ...this.choices[facet].map(choice => ({ facet, choice })),
        ],
        []
      );
    },
  },

  methods: {
    ...mapMutations(['setError', 'setProduct']),

    ensureValid(event) {
      if (!this.isValid) {
        event.preventDefault();

        return false;
      }

      return true;
    },

    componentFor(facet) {
      return facet.choice_limit === 1 ? 'SingleFacet' : 'MultiFacet';
    },

    async applyProduct({ id }) {
      this.loading = true;

      const params = {
        store: this.storeId,
        include: 'facets',
      };

      try {
        const { data: product } = await http.get(`/admin/products/${id}`, {
          params,
        });

        this.setProduct({ product });
      } catch (error) {
        this.error = 'Something went wrong. Please try again.';
      } finally {
        this.loading = false;
      }
    },

    applyError(error = null) {
      this.setError({ error });
    },

    reset() {
      this.setError();
      this.setProduct();
    },
  },

  components: {
    ProductSearch,
    SelectedProduct,
    SingleFacet,
    MultiFacet,
    QuantitySelector,
  },
};
</script>

<style lang="scss" scoped>
.loading {
  padding-left: 20px;
  background-image: url(../../../../images/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: 0 center;
}

.facets {
  display: flex;
  flex-flow: row wrap;

  /deep/ {
    .facet {
      flex-basis: calc(50% - 1rem);

      &.facet--required label:after {
        content: '*';
        color: red;
      }
    }

    .facet:nth-child(odd) {
      margin-right: 1rem;
    }
  }
}
</style>
