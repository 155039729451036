<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label" for="facet_name">Name</label>
          <input
            class="form-control"
            id="facet_name"
            name="name"
            @input="updateFacetAttribute('name', $event.target.value)"
            :value="facet.name"
            :disabled="!original"
          />

          <span class="help-block"
            >ie. <b>Full Sheet Cake Icing Flavor</b></span
          >
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label" for="facet_display_name"
            >Display Name</label
          >
          <input
            class="form-control"
            id="facet_display_name"
            name="display_name"
            @input="updateFacetAttribute('display_name', $event.target.value)"
            :value="facet.display_name"
            :disabled="!original"
          />

          <span class="help-block"
            >Optional; customer-facing. ie. <b>Icing Flavor</b></span
          >
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label" for="facet_description"
            >Description</label
          >
          <input
            class="form-control"
            id="facet_description"
            name="description"
            @input="updateFacetAttribute('description', $event.target.value)"
            :value="facet.description"
            :disabled="!original"
          />

          <span class="help-block">
            Optional text used to explain the product attribute
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3>Response Type</h3>
        <template v-if="editing">
          <p v-if="isType(FREE_RESPONSE)">
            This product attribute prompts the customer to provide typed
            response.
          </p>
          <p v-else-if="isType(CHOICE_SELECT)">
            This product attribute provides a set of predefined choices (defined
            below) for the customer to select from.
          </p>
          <p v-else>
            This product attribute allows a customer to customize a product with
            an inscribed message.
          </p>
        </template>

        <div class="form-group" v-else>
          <facet-type
            value="choice-select"
            label="Predefined Choices"
            help="Provide a set of choices for the customer to choose from."
          >
          </facet-type>

          <facet-type
            value="free-response"
            label="Free Response"
            help="Allow the customer to enter their own choice for this
                attribute. Useful for accepting special
                instructions."
          >
          </facet-type>

          <facet-type
            value="custom-message"
            label="Custom Message"
            help="Allow text to be added to a product (e.g., a cake inscription)."
          >
          </facet-type>
        </div>
      </div>
      <div class="col-md-6">
        <h3>Other Settings</h3>

        <div class="checkbox">
          <label>
            <input type="hidden" value="0" name="required" />
            <input
              type="checkbox"
              value="1"
              name="required"
              :checked="facet.required"
              @input="updateFacetAttribute('required', !facet.required)"
              :disabled="!original"
            />

            <b>Yes</b>, this attribute is <b>required</b>
          </label>
        </div>

        <div class="form-group" v-if="isType(CHOICE_SELECT)">
          <label class="control-label">Choice Limit</label>
          <input
            type="hidden"
            :value="facet.choice_limit"
            name="choice_limit"
          />
          <multiselect
            :options="choiceLimitOptions"
            :value="facet.choice_limit"
            :multiple="false"
            :searchable="false"
            :disabled="!original"
            @input="limit => updateChoiceLimit({ limit })"
            placeholder="Select choice limit..."
          ></multiselect>
          <span class="help-block">
            Limit the # of choices a customer can select for this product
            attribute.
          </span>
        </div>
      </div>
    </div>

    <component
      v-if="choiceBuilderComponent"
      :is="choiceBuilderComponent"
    ></component>
  </div>
</template>

<script>
import FacetChoices from './FacetChoices';
import FacetType from './FacetType';
import store from './store';
import { range } from 'lodash';
import { FREE_RESPONSE, CHOICE_SELECT } from './constants';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import ChoiceSelectBuilder from './ChoiceBuilders/ChoiceSelect';
import CustomMessageBuilder from './ChoiceBuilders/CustomMessage';
import Multiselect from 'vue-multiselect';

export default {
  store,

  props: {
    original: {
      type: Boolean,
      required: true,
    },
    initialName: {
      type: String,
      required: false,
      default: () => '',
    },
    initialDisplayName: {
      type: String,
      required: false,
      default: () => '',
    },
    initialDescription: {
      type: String,
      required: false,
      default: () => '',
    },
    initialRequired: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    initialChoiceLimit: {
      type: Number,
      required: false,
      default: () => 1,
    },
    initialChoices: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialType: {
      type: String,
      required: false,
      default: () => 'choice-select',
    },
    initialFullImageUrl: {
      type: String,
      required: false,
    },
    editing: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  mounted() {
    this.$nextTick(() => {
      const attributes = {
        name: this.initialName,
        display_name: this.initialDisplayName,
        description: this.initialDescription,
        required: this.initialRequired,
        type: this.initialType,
        choice_limit: this.initialChoiceLimit,
        weight: this.initialWeight,
        full_image_url: this.initialFullImageUrl,
      };

      this.updateFacet({ attributes });
      this.setOriginal({ original: this.original });

      this.initialChoices.forEach(choice => this.addChoice({ choice }));

      if (this.original && this.choices.length === 0) {
        // add a blank choice when no choices are available
        this.addChoice({ choice: {} });
      }
    });
  },

  watch: {
    'facet.type'(value) {
      if (this.isType(CHOICE_SELECT)) {
        return;
      }

      // ensure the choice limit is set back to a single select any time the
      // type has been set to something other than CHOICE_SELECT
      this.updateChoiceLimit({
        limit: 1,
      });
    },
  },

  data() {
    return {
      FREE_RESPONSE,
      CHOICE_SELECT,
    };
  },

  computed: {
    // Choice limit for now is a boolean in terms of how Vue views the input,
    // even though the submitted value is currently going to be 1 (hidden default)
    // or 99 (when the box is checked).
    ...mapState(['choices', 'facet']),

    canManageChoices() {
      return !this.isType(FREE_RESPONSE);
    },

    choiceBuilderComponent() {
      if (!this.canManageChoices) {
        return null;
      }

      return `${this.facet.type}-builder`;
    },

    choiceLimitOptions() {
      return range(1, 100, 1);
    },
  },

  methods: {
    ...mapMutations(['addChoice', 'updateFacet', 'setOriginal']),
    ...mapActions(['updateChoiceLimit']),

    isType(type) {
      return this.facet.type === type;
    },

    updateFacetAttribute(attribute, value) {
      const attributes = { [attribute]: value };

      this.updateFacet({ attributes });
    },
  },

  components: {
    FacetChoices,
    FacetType,
    ChoiceSelectBuilder,
    CustomMessageBuilder,
    Multiselect,
  },
};
</script>
