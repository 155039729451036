<template>
  <div>
    <form
      class="form"
      method="post"
      :action="`/admin/orders/${orderId}/${action}`"
      @submit.prevent.stop
      id="add-charge"
    >
      <div class="row">
        <div class="col-xs-4">
          <div class="form-group form-group-sm" :class="{ 'has-error': error }">
            <div class="input-group">
              <div class="input-group-addon">
                <i class="glyphicon glyphicon-usd"></i>
              </div>
              <input type="hidden" name="order" :value="orderId" />
              <input
                type="text"
                class="form-control input-sm"
                placeholder="Amount"
                :value="asCurrency(amount)"
                @blur="event => (amount = asCents(event.currentTarget.value))"
                :readonly="processing"
              />
              <input type="hidden" name="amount" v-model="amount" />
            </div>
          </div>
        </div>

        <div class="col-xs-5">
          <input
            type="text"
            name="notes"
            class="form-control input-sm"
            placeholder="Notes (optional)"
            :readonly="processing"
          />
        </div>

        <div class="col-xs-3 text-right">
          <button
            class="btn btn-primary btn-sm ladda-button"
            data-style="slide-up"
            type="submit"
            @click="apply"
            v-text="action === 'refund' ? 'Issue Refund' : 'Charge Card'"
          ></button>
        </div>
      </div>

      <div class="alert alert-danger" v-text="error" v-if="error"></div>
    </form>
  </div>
</template>

<script>
import * as Ladda from 'ladda';
import { asCents, asCurrency } from 'lib/helpers';

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    action: {
      type: String,
      required: true,
      validate: value => ['charge', 'refund'].includes(value),
    },
  },

  data() {
    return {
      amount: null,
      error: null,
      processing: false,
    };
  },

  methods: {
    asCurrency,
    asCents,

    validate() {
      this.error = null;

      if (this.amount <= 0) {
        this.error = 'The amount must be greater than $0.00.';

        return false;
      }

      return true;
    },

    apply(event) {
      if (!this.validate()) {
        return false;
      }

      const ladda = Ladda.create(event.currentTarget);

      ladda.start();
      this.processing = true;

      this.$el.querySelector('form').submit();

      return true;
    },
  },
};
</script>
