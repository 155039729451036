<template>
  <div class="builder-mode">
    <div v-if="groups.length > 0">
      <facet-group
        v-for="group in groups"
        :group="group"
        :key="group.cid"
      ></facet-group>
    </div>
    <p v-else>No attribute groups have been created for this product.</p>

    <add-facet-group v-if="hasUngroupedFacets && isOriginal"></add-facet-group>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import FacetGroup from './builder/FacetGroup';
import AddFacetGroup from './builder/AddFacetGroup';
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['facetGroups']),
    ...mapGetters(['hasUngroupedFacets', 'isOriginal']),

    groups() {
      return sortBy(this.facetGroups, [o => o.weight]);
    },
  },

  components: {
    FacetGroup,
    AddFacetGroup,
  },
};
</script>

<style lang="scss" scoped>
p {
  font-style: italic;
  opacity: 0.8;
}
</style>
