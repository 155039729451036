<template>
  <div class="attribute-manager" v-if="detailStyle">
    <component v-if="facets.length > 0" :is="`${detailStyle}-mode`"></component>
  </div>
</template>

<script>
import DefaultMode from './DefaultMode';
import BuilderMode from './BuilderMode';
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      facets: state => state.form.facets,
    }),
    ...mapGetters(['detailStyle']),
  },

  components: {
    DefaultMode,
    BuilderMode,
  },
};
</script>
