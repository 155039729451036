<template>
  <div class="dropdown-box" :class="{ selecting }">
    <div class="contents" v-on-clickaway="close">
      <div :class="classes" @click="selecting = !selecting">
        {{ formatted_time }}
      </div>

      <ul class="options">
        <li v-for="time in timesFor()" @click="selectTime(time)">
          {{ time.time.format('h:mm A') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { bus } from 'lib/helpers';
import { mapState, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import ActsAsDropdown from 'mixins/ActsAsDropdown';

export default {
  mixins: [ActsAsDropdown],

  props: {
    times: {
      type: Array,
      required: true,
    },
  },

  watch: {
    day() {
      this.clear();
    },
  },

  computed: {
    ...mapState(['time', 'day']),
    ...mapGetters(['timesFor']),

    formatted_time() {
      return this.time ? moment(this.time).format('h:mm A') : 'Select';
    },

    empty() {
      return !this.time;
    },
  },

  methods: {
    ...mapMutations(['setTime']),

    selectTime(time) {
      if (!time.valid) {
        return;
      }

      this.selecting = false;
      this.setTime({ time: time.time.clone() });
    },

    clear() {
      this.setTime({ time: null });
    },
  },
};
</script>
