<template>
  <tr>
    <slot name="before"></slot>
    <td>
      <img class="img-rounded tiny-thumb" :src="image" />
      <a
        :href="`/admin/products/${product.id}/edit?${originType}=${originId}`"
        target="_blank"
      >
        {{ product.name }}
      </a>
    </td>
    <td>
      <code>{{ product.upc_13 }}</code>
    </td>
    <td v-if="allowRemoval">
      <button
        class="btn btn-xs btn-danger pull-right"
        @click.stop.prevent="remove()"
      >
        Remove
      </button>
    </td>
    <td v-else></td>
    <slot name="after"></slot>
  </tr>
</template>

<script>
export default {
  props: {
    originType: {
      type: String,
      required: true,
    },
    originId: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    allowRemoval: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },

  computed: {
    image() {
      return (
        this.product.image_url || require('../../images/default-product.jpg')
      );
    },
  },

  methods: {
    remove() {
      this.$emit('remove');
    },
  },
};
</script>
