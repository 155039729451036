<template>
  <div class="form-group">
    <input
      type="hidden"
      name="facets[]"
      v-for="facet in orderedFacets"
      :key="facet.id"
      :value="facet.id"
    />

    <multiselect
      label="name"
      track-by="id"
      open-direction="below"
      :options="options"
      :value="facets"
      :multiple="true"
      :searchable="true"
      @input="updateSelection"
      @open="onOpenMultiselect"
      @close="onCloseMultiselect"
      placeholder="Select attributes..."
    ></multiselect>

    <div class="attribute-dnd" v-if="facets.length > 1 && isOriginal">
      <p>
        Drag and drop the attributes below to set the order they should appear
        to customers.
      </p>
      <draggable :value="orderedFacets" @input="updateOrder">
        <div class="dnd-item" v-for="facet in facets" :key="facet.id">
          <i class="glyphicon glyphicon-resize-vertical"></i>
          <span v-text="facet.name"></span>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { delay, differenceBy } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import draggable from 'vuedraggable';

export const sortByWeight = (a, b) =>
  (a.weight || 0) < (b.weight || 0) ? -1 : 1;

export default {
  mounted() {
    const searchInput = document.querySelector('.multiselect__input');
    searchInput.classList.toggle('multiselect__input-hide');
  },

  computed: {
    ...mapGetters(['allFacets', 'isOriginal']),
    ...mapState(['facets']),

    orderedFacets() {
      return this.facets.sort(sortByWeight);
    },

    options() {
      return differenceBy(this.allFacets, this.facets, o => o.id);
    },
  },

  methods: {
    updateSelection(facets) {
      this.$store.commit('setFacets', { facets });
    },

    updateOrder(facets) {
      facets.forEach((facet, index) => (facet.weight = index));

      this.$store.commit('setFacets', { facets });
    },

    onOpenMultiselect() {
      const searchInput = document.querySelector('.multiselect__input');
      searchInput.classList.toggle('multiselect__input-hide');
    },

    onCloseMultiselect() {
      const searchInput = document.querySelector('.multiselect__input');
      searchInput.classList.toggle('multiselect__input-hide');
    },
  },

  components: {
    Multiselect,
    draggable,
  },
};
</script>

<style lang="scss" scoped>
.attribute-dnd {
  margin: 1rem 0;

  i {
    opacity: 0.8;
  }

  .dnd-item {
    padding: 0.5rem;
    border-radius: 3px;
    background: rgba(black, 0.04);
    cursor: grab;

    + .dnd-item {
      margin-top: 0.5rem;
    }
  }
}
</style>
