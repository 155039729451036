<template>
  <tr>
    <td v-text="facet.name"></td>
    <td v-text="choice.value"></td>
    <td>
      <input
        type="hidden"
        :name="`facet_groups[${group.cid}][triggers][${cid}][facet_id]`"
        :value="trigger.facet_id"
      />
      <input
        type="hidden"
        :name="`facet_groups[${group.cid}][triggers][${cid}][choice_id]`"
        :value="trigger.choice_id"
      />
      <button
        type="button"
        class="btn btn-danger btn-xs pull-right"
        v-if="isOriginal"
        @click="remove()"
      >
        Remove
      </button>
    </td>
  </tr>
</template>

<script>
import { token } from 'lib/helpers';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    trigger: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cid: token(),
    };
  },

  computed: {
    ...mapState(['facetGroups']),
    ...mapGetters(['allFacets', 'isOriginal']),

    /**
     * Find the group the facet target belongs to
     */
    group() {
      return this.facetGroups.find(g =>
        g.facets.some(f => f.id === this.trigger.facet_id)
      );
    },

    /**
     * The facet is NOT the one matching the facet_id on the trigger (the facet_id
     * is the target of the trigger, not the owner of the choice). Instead, the
     * facet is determined by walking through allFacets, looking for the one
     * containing the choice.
     */
    facet() {
      return this.allFacets.find(f =>
        f.choices.map(c => c.id).includes(this.trigger.choice_id)
      );
    },

    choice() {
      return this.facet.choices.find(c => c.id === this.trigger.choice_id);
    },
  },

  methods: {
    remove() {
      this.$store.commit('removeTriggerFromGroup', { trigger: this.trigger });
    },
  },
};
</script>
