<template>
  <div class="row">
    <div class="col-xs-12">
      <button
        type="button"
        class="btn btn-sm btn-primary pull-right"
        @click="addChoice({})"
        v-if="isOriginal"
      >
        Add Choice
      </button>

      <h4 v-text="title"></h4>

      <facet-choice
        :name-label="nameLabel"
        v-for="choice in visibleChoices"
        :allow-images="allowImages"
        :key="choice.cid"
        :choice="choice"
      >
        <template v-slot:additional-fields="slotProps">
          <slot name="additional-fields" v-bind="slotProps"></slot>
        </template>
      </facet-choice>

      <slot name="conclusion"></slot>

      <input
        type="hidden"
        v-for="choice in deletedChoices"
        :key="`${choice.cid}_delete`"
        :name="`choices[${choice.cid}][_delete]`"
        value="1"
      />
      <input
        type="hidden"
        v-for="choice in deletedChoices"
        :key="`${choice.cid}_id`"
        :name="`choices[${choice.cid}][id]`"
        :value="choice.id"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import FacetChoice from './FacetChoice';

export default {
  props: {
    nameLabel: {
      type: String,
      required: false,
      default: () => 'Name',
    },
    allowImages: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(['choices']),
    ...mapGetters(['visibleChoices', 'deletedChoices', 'isOriginal']),
  },

  methods: {
    ...mapMutations(['addChoice']),
  },

  components: {
    FacetChoice,
  },
};
</script>
