<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <a
        v-if="canDelete"
        href="#"
        class="pull-right btn btn-xs btn-danger"
        @click.stop.prevent="destroy()"
      >
        Remove
      </a>

      {{ choice.value || 'Choice' }}
    </div>
    <div class="panel-body">
      <input
        type="hidden"
        :name="`choices[${choice.cid}][id]`"
        :value="choice.id"
        v-if="choice.id"
      />
      <input
        type="hidden"
        :name="`choices[${choice.cid}][meta]`"
        :value="JSON.stringify(choice.meta)"
      />

      <div class="row">
        <facet-choice-input
          :columns="3"
          :label="nameLabel"
          :value="choice.value"
          @blur="value => applyUpdate({ value })"
          placeholder="Choice"
          :name="`choices[${choice.cid}][value]`"
          :disabled="!isOriginal"
        >
        </facet-choice-input>

        <facet-choice-input
          :columns="4"
          label="Description"
          help="Optional text used to explain the choice"
          :value="choice.description"
          @blur="description => applyUpdate({ description })"
          :name="`choices[${choice.cid}][description]`"
          :disabled="!isOriginal"
        >
        </facet-choice-input>

        <slot name="additional-fields" v-bind="{ choice, updateMeta }">
          <facet-choice-input
            :columns="3"
            label="Price Modifier"
            :name="`choices[${choice.cid}][srp_amount]`"
            placeholder="Price"
            @blur="updatePrice"
            :value="asCurrency(choice.srp_amount)"
          >
            <template v-slot:input-addon>
              <div class="input-group-addon">
                <i class="glyphicon glyphicon-usd"></i>
              </div>
            </template>
          </facet-choice-input>

          <facet-choice-input
            :columns="2"
            label="Calorie Modifier"
            type="number"
            :value="choice.calorie_modifier"
            @blur="updateCalories"
            placeholder="Calories"
            :name="`choices[${choice.cid}][calorie_modifier]`"
            :disabled="!isOriginal"
          ></facet-choice-input>
        </slot>
      </div>

      <div class="form-group form-group-sm">
        <div class="checkbox">
          <label>
            <input
              type="hidden"
              value="0"
              :name="`choices[${choice.cid}][default]`"
            />
            <input
              type="checkbox"
              @input="toggle()"
              :checked="choice.default"
              :name="`choices[${choice.cid}][default]`"
              :disabled="!isOriginal || !canSelect"
              value="1"
            />
            <strong>Yes,</strong> this choice should be selected by default
          </label>
        </div>
      </div>

      <div class="row" v-if="allowImages">
        <div class="col-md-6">
          <media-card
            label="Thumbnail Image"
            type="image"
            :field-name="`choices[${choice.cid}][thumbnail_image]`"
            :url="choice.thumbnail_image_url"
            :readonly="!isOriginal"
            :removable="false"
          ></media-card>
        </div>

        <div class="col-md-6">
          <media-card
            type="image"
            label="Overlay Image for Builder Mode"
            :field-name="`choices[${choice.cid}][full_image]`"
            :url="choice.full_image_url"
            :readonly="!isOriginal"
            :removable="false"
          >
            <template v-slot:help>
              Used as a layer for a product's Builder Mode preview.
            </template>
          </media-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus, asCents, asCurrency } from 'lib/helpers';
import FacetChoiceInput from './FacetChoiceInput';
import MediaCard from 'components/MediaCard.vue';
import * as constants from './constants';
import { mapActions, mapGetters, mapState } from 'vuex';
import { set } from 'lodash';

export default {
  props: {
    nameLabel: {
      type: String,
      required: false,
      default: () => 'Name',
    },
    choice: {
      type: Object,
      required: true,
    },
    allowImages: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },

  data() {
    return {
      selected: this.choice.default,
      amount: this.choice.srp_amount,
    };
  },

  computed: {
    ...mapGetters(['visibleChoices', 'defaultChoices', 'isOriginal']),
    ...mapState(['facet']),

    canEdit() {
      return this.isOriginal;
    },

    canDelete() {
      return (
        this.isOriginal && this.visibleChoices.length > constants.MIN_CHOICES
      );
    },

    canSelect() {
      return (
        this.facet.choice_limit === 1 ||
        this.defaultChoices.length < this.facet.choice_limit
      );
    },
  },

  methods: {
    ...mapActions(['updateChoice']),

    asCurrency,

    /**
     * Updates a path on the `meta` attribute for a choice.
     *
     * This currently only supports one level of depth.
     */
    updateMeta(path, value) {
      const meta = this.choice.meta;

      set(meta, path, value);

      this.applyUpdate({ meta });
    },

    updatePrice(value) {
      this.applyUpdate({
        srp_amount: asCents(value, { allowNegative: true }),
      });
    },

    updateCalories(value) {
      this.applyUpdate({ calorie_modifier: Number(value) });
    },

    destroy() {
      this.applyUpdate({ _delete: true });
    },

    toggle() {
      this.applyUpdate({ default: !this.choice.default });
    },

    applyUpdate(attributes = {}) {
      this.updateChoice({ cid: this.choice.cid, attributes });
    },
  },

  components: {
    MediaCard,
    FacetChoiceInput,
  },
};
</script>
