<template>
  <div class="form-group form-group-sm">
    <label>Add Trigger</label>

    <div class="row">
      <div class="col-xs-5">
        <select v-model="facetId" class="form-control">
          <option value="" disabled>Select attribute...</option>
          <option
            v-for="facet in facets"
            :key="facet.id"
            :value="facet.id"
            v-text="facet.name"
          ></option>
        </select>
      </div>
      <div class="col-xs-5">
        <select v-if="choices.length" v-model="choiceId" class="form-control">
          <option value="" disabled>Select choice...</option>
          <option
            v-for="choice in choices"
            :key="choice.id"
            :value="choice.id"
            v-text="choice.value"
          ></option>
        </select>
      </div>
      <div class="col-xs-2">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          :disabled="!facetId || !choiceId"
          @click="add()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    facet: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },

  watch: {
    facetId() {
      this.choiceId = '';
    },
  },

  data() {
    return {
      facetId: '',
      choiceId: '',
    };
  },

  computed: {
    ...mapGetters(['allFacets']),

    choices() {
      if (!this.facetId) {
        return [];
      }

      return this.availableTriggerChoicesFor(this.facetId);
    },

    facets() {
      const whitelist = this.group.facets
        .filter(f => f.id !== this.facet.id)
        .map(f => f.id);

      return this.allFacets
        .filter(f => whitelist.includes(f.id)) // *other* facets for this group
        .filter(f => this.availableTriggerChoicesFor(f.id).length > 0); // that have 1+ choices that haven't been used as a trigger for this target
    },
  },

  methods: {
    availableTriggerChoicesFor(facetId) {
      const blacklist = this.group.triggers
        .filter(t => t.facet_id === this.facet.id)
        .map(t => t.choice_id);

      // only show choices for the selected facet which have not already been used
      // as a trigger for the target.
      return this.allFacets
        .find(f => f.id === facetId)
        .choices.filter(c => !blacklist.includes(c.id));
    },

    add() {
      const cid = this.group.cid;
      const trigger = {
        facet_id: this.facet.id, // note, the facet id for a trigger is NOT the owner of the choice, but the *target* of the trigger
        choice_id: this.choiceId,
      };

      this.$store.commit('addTriggerToGroup', { cid, trigger });

      this.facetId = '';
      this.choiceId = '';
    },
  },
};
</script>
