<template>
  <div>
    <product-finder
      :product-id="targetId"
      :store-id="storeId"
      :department-id="departmentId"
      v-on:selected="selectProduct"
    ></product-finder>
    <input type="hidden" name="target_id" :value="product.id" v-if="product" />
  </div>
</template>

<script>
import ProductFinder from 'components/ProductSearch/ProductFinder';

export default {
  props: {
    targetId: {
      type: Number,
      required: false,
    },
    departmentId: {
      type: Number,
      required: true,
    },
    storeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      product: null,
    };
  },

  methods: {
    selectProduct(product) {
      this.product = product;
    },
  },

  components: {
    ProductFinder,
  },
};
</script>

<style lang="scss" scoped></style>
