<template>
  <div class="form-group">
    <label for="page_click_box">Page Click Box</label>
    <div class="input-group">
      <select
        class="form-control selectize"
        id="page_click_box"
        v-model="page_id"
      >
        <option value="">Select Catalog Page</option>
        <option v-for="page in other_pages" :value="page.id">
          {{ page.number }}. {{ page.name }}
        </option>
      </select>
      <span class="input-group-btn">
        <button
          class="btn btn-primary"
          :disabled="!page_id"
          @click.stop.prevent="addPage()"
        >
          Add Click Box
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      page_id: null,
    };
  },

  computed: {
    ...mapState(['pages']),

    other_pages() {
      return this.pages.filter(p => Number(p.number) !== Number(this.number));
    },
  },

  methods: {
    ...mapActions(['addHitboxFromTarget']),

    addPage() {
      const target = this.pages.find(
        p => Number(p.id) === Number(this.page_id)
      );

      this.addHitboxFromTarget({ target: { ...target, type: 'page' } });
    },
  },
};
</script>
