<template>
  <div id="product-search" v-on-clickaway="blur">
    <div class="form-group">
      <label v-if="label" v-text="label"></label>
      <input
        type="text"
        class="form-control"
        :class="{ searching }"
        @focus="focused = true"
        placeholder="Begin typing to search..."
        v-model="fragment"
      />
      <span class="help-block" v-text="hint" v-if="hint"></span>
    </div>

    <ul class="results" v-if="results.length && focused">
      <li v-for="result in results" :key="result.id" @click="select(result)">
        <div class="preview">
          <img class="img-rounded tiny-thumb" :src="productImage(result)" />
        </div>
        <span class="name">{{ result.name }}</span>
        <code>{{ result.upc_13 }}</code>
      </li>
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { debounce } from 'lodash';
import { http } from 'lib/helpers';

const THROTTLE_DELAY = 250;

export default {
  mixins: [clickaway],

  props: {
    label: {
      type: String,
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },
    originType: {
      type: String,
      required: true,
    },
    originId: {
      type: Number,
      required: true,
    },
    queryParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  watch: {
    fragment(v) {
      this.results = [];

      this.search(v);
    },
  },

  data() {
    return {
      focused: false,
      searching: false,
      fragment: '',
      results: [],
    };
  },

  methods: {
    productImage(result) {
      return result.image_url || require('../../images/default-product.jpg');
    },

    search: debounce(async function () {
      if (this.fragment.length < 3) {
        return;
      }

      this.searching = true;
      this.$emit('searching');

      const params = {
        ...this.queryParams,
        fragment: this.fragment,
        [this.originType]: this.originId,
      };

      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
      };

      try {
        const {
          data: { data: results },
        } = await http.get('/admin/products', { params, headers });

        if (results.length === 0) {
          throw new Error('No results');
        }

        this.results = results;
      } catch (error) {
        this.$emit(
          'error',
          `No products matching <b>&ldquo;${this.fragment}&rdquo;</b> could be found.`
        );
      } finally {
        this.searching = false;
      }
    }, THROTTLE_DELAY),

    select(product) {
      this.fragment = '';
      this.focused = false;

      this.$emit('selected', product);
    },

    blur() {
      this.focused = false;
    },
  },
};
</script>

<style lang="scss">
#product-search {
  position: relative;

  .results {
    position: absolute;
    background: white;
    z-index: 300;
    min-width: 340px;
    top: calc(100% - 2px);
    width: 100%;
    left: 0;
    max-height: 300px;
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &,
    li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 4px 8px;
      cursor: pointer;
      display: flex;
      align-items: center;

      code {
        margin-left: auto;
      }

      .preview {
        width: 30px;

        img {
          height: auto;
          max-width: 20px;
          max-height: 20px;
        }
      }
    }

    li:hover {
      background-color: #f5f5f5;
    }

    li:nth-child(even) {
      background-color: #fafafa;
    }

    li:nth-child(even):hover {
      background-color: #f2f2f2;
    }
  }

  input.searching {
    background-image: url(../../images/ajax-loader.gif);
    background-position: right 8px center;
    background-repeat: no-repeat;
  }
}
</style>
