<template>
  <div>
    <hr />

    <h3>Product Image</h3>

    <div id="product-images">
      <div :disabled="isReadonly" class="js-linked-attribute">
        <media-card
          type="image"
          v-for="(image, index) in visibleImages"
          :key="image.id"
          :index="index"
          :class="{ primary: isPrimary(image) }"
          :label="`Image ${index + 1}`"
          :fieldName="`images[${index}][source]`"
          :url="image.image_url"
          :removable="false"
          :readonly="isReadonly"
          :altText="image.alt_text"
          :showAltTextField="true"
          :altTextFieldName="`images[${index}][alt_text]`"
        >
          <template v-slot:description>
            <small class="label label-info" v-if="isPrimary(image)">
              PRIMARY
            </small>

            <a
              href="javascript:void(0);"
              class="btn btn-xs btn-default"
              v-if="!isPrimary(image) && image.image_url"
              @click.prevent.stop="setPrimaryImage(image)"
              >Make Primary</a
            >
            <a
              href="javascript:void(0);"
              class="delete-link"
              @click.prevent.stop="remove(image)"
              v-if="visibleImages.length > 1 || image.image_url"
              >Delete</a
            >

            <input
              type="hidden"
              :name="`images[${index}][id]`"
              :value="image.id"
              v-if="image.image_url"
            />
            <input
              type="hidden"
              :name="`images[${index}][primary]`"
              :value="Number(isPrimary(image))"
            />
          </template>
        </media-card>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div
            class="checkbox unlinkable-attribute-toggle"
            v-if="isOriginal && isImported"
          >
            <label>
              <input
                type="hidden"
                name="unlinked_attributes[images]"
                value="1"
              />
              <input
                type="checkbox"
                name="unlinked_attributes[images]"
                @change="toggle()"
                :checked="isLinkedAttribute('images')"
                value="0"
              />
              Automatically sync the <b>product images</b>
            </label>
          </div>
        </div>
        <div class="col-md-4" :disabled="isReadonly">
          <a
            href="#"
            @click.stop.prevent="addImage()"
            class="pull-right"
            v-if="visibleImages.length < max"
            >Add Another Image</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaCard from 'components/MediaCard.vue';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

// Manages addition and removal of images for a product.
export default {
  data() {
    return {
      max: 5,
    };
  },

  computed: {
    ...mapState(['images']),
    ...mapGetters([
      'primaryImage',
      'isOriginal',
      'isImported',
      'visibleImages',
      'isLinkedAttribute',
      'isEditing',
    ]),

    isReadonly() {
      if (!this.isEditing) {
        return false;
      }

      if (!this.isOriginal) {
        return true;
      }

      if (this.isImported) {
        return this.isLinkedAttribute('images');
      }

      return false;
    },
  },

  methods: {
    ...mapMutations(['addImage', 'setPrimaryImage']),

    remove(image) {
      if (image.id && !confirm('Are you sure you want to delete this image?')) {
        return;
      }

      this.$store.dispatch('removeImage', { cid: image.cid });
    },

    isPrimary(image) {
      console.log(image);
      return this.primaryImage.cid === image.cid;
    },

    toggle() {
      this.$store.commit('toggleLinkedAttribute', { attribute: 'images' });
    },
  },

  components: {
    MediaCard,
  },
};
</script>
