<template>
  <div class="form-group">
    <label for="product_click_box">Product Click Box</label>

    <product-search
      origin-type="group"
      :origin-id="groupId"
      v-on:error="applyError"
      v-on:searching="applyError"
      v-on:selected="addProduct"
    >
    </product-search>
  </div>
</template>

<script>
import qs from 'query-string';
import { head } from 'lodash';
import { mapMutations, mapActions, mapState } from 'vuex';
import ProductSearch from 'components/ProductSearch/Search';

export default {
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },

  computed: mapState(['error']),

  methods: {
    ...mapMutations(['setError']),
    ...mapActions(['addHitboxFromTarget']),

    addProduct(product) {
      this.addHitboxFromTarget({
        target: { ...product, type: 'product' },
      });
    },

    applyError(error = null) {
      this.setError({ error });
    },
  },

  components: {
    ProductSearch,
  },
};
</script>
