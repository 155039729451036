<template>
  <facet-choices title="Predefined Choices" :allow-images="true">
    <template v-slot:conclusion>
      <p class="help-block">
        <span class="label-xs label label-info">Note</span> The
        <b>Calorie Modifier</b> accepts both positive and negative values.
      </p>
    </template>
  </facet-choices>
</template>

<script>
import FacetChoices from '../FacetChoices';

export default {
  components: {
    FacetChoices,
  },
};
</script>
