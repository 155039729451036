<template>
  <div>
    <div class="form-group">
      <label>New Group Name</label>
      <input type="text" v-model="name" class="form-control" />
    </div>

    <button
      type="button"
      @click="add()"
      class="btn btn-primary btn-sm"
      :disabled="name.trim().length === 0"
    >
      Add Group
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
    };
  },

  methods: {
    add() {
      const group = { name: this.name };

      this.$store.commit('addFacetGroup', { group });

      // clear the field after commiting the new group to the store
      this.name = '';
    },
  },
};
</script>
