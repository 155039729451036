<template>
  <div
    class="checkbox unlinkable-attribute-toggle"
    v-if="isImported && isOriginal"
  >
    <label>
      <input
        type="hidden"
        :name="`unlinked_attributes[${attribute}]`"
        value="1"
      />
      <input
        type="checkbox"
        :name="`unlinked_attributes[${attribute}]`"
        value="0"
        :checked="isLinkedAttribute(attribute)"
        @change="toggle()"
      />
      Automatically sync the <b>product {{ displayName }}</b>
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    attribute: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  computed: {
    ...mapGetters(['isImported', 'isOriginal', 'isLinkedAttribute']),

    displayName() {
      return (this.name.trim().length ? this.name : this.attribute).trim();
    },
  },

  methods: {
    toggle() {
      this.$store.commit('toggleLinkedAttribute', {
        attribute: this.attribute,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
