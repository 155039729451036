<template>
  <div class="radio">
    <label>
      <input
        type="radio"
        name="type"
        :value="value"
        :checked="facet.type === value"
        @change="setType(value)"
      />
      {{ label }}
    </label>
    <p class="help-block"><small v-text="help"></small></p>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(['facet']),
  },

  methods: {
    ...mapMutations(['updateFacet']),

    setType(value) {
      const attributes = { type: value };

      this.updateFacet({ attributes });
    },
  },
};
</script>

<style lang="scss" scoped></style>
