<template>
  <div class="dropdown-box" :class="{ selecting }">
    <div class="contents" v-on-clickaway="close">
      <div :class="classes" @click="selecting = !selecting">
        {{ formatted_day }}
      </div>

      <ul class="options">
        <li v-for="day in days" @click="selectDay(day)">
          {{ day | calendar }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mixin as clickaway } from 'vue-clickaway';
import { bus } from 'lib/helpers';
import { mapState, mapGetters, mapMutations } from 'vuex';
import ActsAsDropdown from 'mixins/ActsAsDropdown';

const toRelativeDay = dt =>
  moment(dt).calendar(null, {
    sameDay: '[Today,] MMMM D',
    nextDay: '[Tomorrow,] MMMM D',
    nextWeek: 'dddd, MMMM D',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd, MMMM D',
    sameElse: 'dddd, MMMM D',
  });

export default {
  mixins: [ActsAsDropdown],

  computed: {
    ...mapState(['day']),
    ...mapGetters(['days']),

    formatted_day() {
      return this.day ? toRelativeDay(this.day) : 'Select';
    },

    empty() {
      return !this.day;
    },
  },

  methods: {
    ...mapMutations(['setDay']),

    selectDay(day) {
      this.selecting = false;

      this.setDay({ day: day.clone() });
    },
  },

  filters: {
    calendar: toRelativeDay,
  },
};
</script>
