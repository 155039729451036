<template>
  <div>
    <div class="row">
      <div class="col-xs-6"><day-picker :days="days"></day-picker></div>

      <div class="col-xs-6"><time-picker :times="times"></time-picker></div>

      <input
        type="hidden"
        name="pickup_at"
        v-if="pickup_at"
        :value="pickup_at"
      />
    </div>

    <button
      type="submit"
      class="btn btn-success ladda-button"
      data-style="slide-up"
      :disabled="!pickup_at"
    >
      Change Pick Up Time
    </button>
  </div>
</template>

<script>
import moment from 'moment';
import store from './store';
import { bus, http } from 'lib/helpers';
import DayPicker from './DayPicker';
import TimePicker from './TimePicker';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  store,

  props: {
    departmentId: {
      type: Number,
      required: true,
    },
    leadTime: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },

  mounted() {
    this.$nextTick(async () => {
      this.$timer = setInterval(() => {
        if (moment().seconds() === 0) {
          this.now = moment();
        }
      }, 1000);

      const { data } = await http.get(
        `/admin/departments/${this.departmentId}/blackouts`
      );

      this.initialize({
        blackouts: data.data,
        ...data.meta,
        lead_time: this.leadTime,
      });
    });
  },

  destroyed() {
    clearTimeout(this.$timer);
  },

  watch: {
    now() {
      if (!this.isValid(this.time)) {
        this.setEarliestValidTime();
      }
    },

    blackouts() {
      this.setEarliestValidTime();
    },
  },

  data() {
    return {
      now: moment(),
    };
  },

  computed: {
    ...mapState(['day', 'time', 'blackouts']),
    ...mapGetters(['days', 'timesFor']),

    pickup_at() {
      if (!this.time || !this.day) {
        return null;
      }

      const time = this.day
        .clone()
        .hours(this.time.hours())
        .minutes(this.time.minutes())
        .seconds(0);

      if (!this.isValid(time)) {
        return null;
      }

      return time.utc().format('YYYY-MM-DD HH:mm:ss');
    },

    times() {
      return this.timesFor(this.day);
    },
  },

  methods: {
    ...mapMutations(['setDay', 'setTime']),
    ...mapActions(['initialize']),

    setEarliestValidTime() {
      const days = this.days;

      for (let index in days) {
        const day = days[index];
        const time = this.timesFor(day).find(t => t.valid);

        if (time) {
          this.setDay({ day: day });
          this.setTime({ time: time.time });

          return;
        }
      }
    },

    isValid(time) {
      return !!this.days.find(day =>
        this.timesFor(day).find(t => t.valid && t.time.isSame(time, 'minute'))
      );
    },
  },

  components: { DayPicker, TimePicker },
};
</script>

<style lang="scss">
.dropdown-box {
  margin-bottom: 2rem;
  cursor: pointer;
}

.dropdown-box .contents {
  position: relative;
}

.dropdown-box .selected {
  border: 1px solid #ccc;
  padding: 0.75rem 1rem;
  border-radius: 3px;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 8px;
    height: 8px;
    transform: translateY(-50%);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAMAAABrrFhUAAAA4VBMVEUAAAB5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXl5eXlwlLcHAAAASnRSTlMAAQIDBAYHCQ0OEBESFBUWFxgaHCIlJiowMTU2ODlHSUtMTVteY2tsb3F0dXd8iYuSlJq1t7m8w8fIyszOz9fi5Obt7/P19/n7/X3aZyAAAANjSURBVBgZ7cGHYlVVFATQSQgRBcGNBVFULKAmg2LDFlTCWDL//0ECKhJ4SV655ZS9FlJKKaWUUkoppZRSSimllFJKKaWUUkoppZRSSimllFJKKaXUpK3LNz7+dK9tn9/64M3zWOjVO7+7E9+9t4XnvfS1e/LrdRz3zh/uzFfn8Ixb7s9Pu3jqtnv0y3n863336fstPHH5yJ26gyd+cLdexyPvul/38MjP7thV4JJ7RuBD9+w34K67tosDdy3w0F17C3+5a9fwwF17A9+6axdBd20b192ze8DOkTt2E8CX7tfRLoCL7tceHtt3rw538Nj2fXfqGv7x8qG79An+c0Xu0D7+F3J3iGeF3BniuJC7Qjwv5I4QLwq5G8QiIXeCWCzkLhAnCbkDxMlCbh5xmpAbR5wu5KYRZwm5YcTZQm4WsYyQG0UsJ+QmEcsKuUHE8kJuDrGKkBtDrCbkphCrCrkhxOpCbgaxjpAbQawn5CYQ6wq5AcT6Qq4esYmQK0dsJuSqEZsKuWLE5kKuFjGEkCtFDCPkKhFDCblCxHBCrg4xpJArQwwr5KoQQwu5IsTwQq4GMYaQK0GMI+QqEGMJuQLEeEIuHjGmkAtHjCvkohFjC7lgxPhCLhYxhZALRUwj5CIRUwm5QMR0Qi4OMaWQC0NMK+SiEFMLuSDE9EIuBjGHkAtBzCPkIhBzCbkAxHxCnh0xp5BnRswr5FkRcwt5RsT8Qp4NUYKQZ0KUIeRZEKUIeQZEOUKeHFGSkCdGlCXkSRGlCXlCRHlCngxRopAnQpQp5EkQpQp5AkS5Qh4dUbKQR0aULeRREaULeURE+UIeDVGDkEdC1CHkURC1CHkERD1CHhxRk5AHRtQl5EERtQl5QER9Qh4MUaOQB0LUKeRBELUKeQBEvULeGFGzkDdE1C3kjRC1C3kDRP1CXhvRgpDXRLQh5LUQrQh5DUQ7Ql4Z0ZKQV0S0JeSVEK0JeQVEe0JeGtGikJdEtCnkpRCtCnkJRLtCPhPRspDPQLQt5FMRrQv5FET7Qj4R0YOQT0D0IeSFiF5cOfQCn6EfF+77BTfRk+09H/fja+jMK18c+amDG+jQztv73zz48+HB3Y8uIaWUUkoppZRSSimllFJKKaWUUkoppZRSSimllFJKKaWUUkppc38Dkv+iItb6i+YAAAAASUVORK5CYII=');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.dropdown-box.selecting .selected:after {
  transform-origin: center;
  transform: translateY(-50%) rotate(180deg);
}

.dropdown-box .placeholder {
  color: #888;
}

.dropdown-box ul,
.dropdown-box li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-box ul {
  display: none;
}

.dropdown-box.selecting ul {
  display: block;
  width: 100%;
  margin-top: -1px;
  position: absolute;
  background: white;
  z-index: 100;
  border: 1px solid #ccc;
  border-top-color: #eee;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  max-height: 300px;
  overflow: auto;
}

.dropdown-box li {
  padding: 0.75rem 1rem;
}

.dropdown-box li:hover {
  background-color: #f4f4f4;
}

.dropdown-box li:nth-child(odd) {
  background-color: #f9f9f9;
}

.dropdown-box li:nth-child(odd):hover {
  background-color: #f2f2f2;
}
</style>
