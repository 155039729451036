<template>
  <div class="target-category">
    <div class="tree js-tree"></div>
    <input type="hidden" name="target_id" :value="selected" />
  </div>
</template>

<script>
import CategoryTree from 'lib/Trees/CategoryTree';
import { http } from 'lib/helpers';
import { head } from 'lodash';
import Vue from 'vue';

class Tree extends CategoryTree {
  constructor(component, source = []) {
    super(component.$el.querySelector('.js-tree'), source, {
      core: {
        multiple: false,
      },
    });

    this.component = component;
  }

  /**
   * When the jstree selection changes, the id of the selected category should be
   * set back in the category tree component.
   */
  onChange(e, data) {
    const selected = head(data.selected) || '';
    const categoryId = Number(head(selected.split('-').reverse()));

    Vue.set(this.component, 'selected', categoryId || null);
  }

  /**
   * When the tree has been rendered or refreshed, if the pseudo-department target
   * has an id value set on the component, an attempt will be made to select the
   * node in the tree.
   *
   * No 'changed.jstree' event will fire during this selection.
   *
   * This is used to select the appropriate category in the initial tree loaded
   * when editing a pseudo-department.
   */
  onRender() {
    const selected = this.component.$data.selected;

    if (selected) {
      this.tree.select_node([`category-${selected}`], true);
    }
  }
}

export default {
  props: {
    targetId: {
      type: Number,
      required: false,
    },
    departmentId: {
      type: Number,
      required: true,
    },
    storeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      selected: this.targetId,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$tree = new Tree(this);
      this.updateTree();
    });
  },

  watch: {
    departmentId() {
      this.updateTree();
    },
  },

  methods: {
    async updateTree() {
      const { data: categories } = await http.get(
        `/admin/departments/${this.departmentId}/categories`
      );

      this.$tree.setSource(categories);
      this.$tree.render();
    },
  },
};
</script>

<style lang="scss" scoped></style>
