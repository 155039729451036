<template>
  <div :class="`col-sm-${columns}`">
    <div class="form-group form-group-sm">
      <label class="control-label" v-text="label"></label>
      <div :class="{ 'input-group': this.$slots['input-addon'] }">
        <slot name="input-addon"></slot>
        <input
          :type="type"
          class="form-control"
          :value="value"
          :name="name"
          @blur="$emit('blur', $event.target.value)"
          :placeholder="placeholder"
          :disabled="disabled"
        />
      </div>
      <span class="help-block" v-if="help.length" v-text="help"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Number,
      required: false,
      default: () => 2,
    },
    type: {
      type: String,
      required: false,
      default: () => 'text',
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: () => null,
    },
    value: {
      required: true,
    },
    help: {
      type: String,
      required: false,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
