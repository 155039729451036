<template>
  <div>
    <p v-if="loading"><small class="text-muted">Loading product...</small></p>

    <template v-else>
      <table class="table table-striped" v-if="product.id">
        <selected-product
          :product="product"
          origin-type="store"
          :origin-id="storeId"
          v-on:remove="selectProduct()"
        ></selected-product>
      </table>

      <product-search
        v-else
        origin-type="store"
        :origin-id="storeId"
        :query-params="{ page: 0, department: departmentId }"
        v-on:error="setError"
        v-on:searching="setError()"
        v-on:selected="selectProduct"
      >
      </product-search>

      <div class="alert alert-danger" v-if="error" v-html="error"></div>
    </template>
  </div>
</template>

<script>
import { el } from 'lib/helpers';
import ProductSearch from './Search';
import SelectedProduct from './SelectedProduct';
import { http } from 'lib/helpers';

export default {
  props: {
    productId: {
      type: Number,
      required: false,
    },
    storeId: {
      type: Number,
      required: true,
    },
    departmentId: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.$nextTick(async () => {
      const params = { store: this.storeId };

      if (!this.productId) {
        return;
      }

      this.loading = true;

      try {
        const { data } = await http.get(`/admin/products/${this.productId}`, {
          params,
        });

        this.selectProduct(data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    });
  },

  data() {
    return {
      error: '',
      product: {},
      loading: false,
    };
  },

  methods: {
    setError(error = '') {
      this.error = error;
    },

    selectProduct(product = {}) {
      this.product = product;

      this.$emit('selected', product);
    },
  },

  components: {
    ProductSearch,
    SelectedProduct,
  },
};
</script>
