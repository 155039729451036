<template>
  <div>
    <div class="form-group">
      <label>New Attribute</label>
      <select v-model="facetId" class="form-control">
        <option disabled value="">Select an attribute...</option>
        <option
          v-for="facet in facets"
          :value="facet.id"
          :key="facet.id"
          v-text="facet.name"
        ></option>
      </select>
    </div>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="add()"
      :disabled="facetId === ''"
    >
      Add Attribute
    </button>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      facetId: '',
    };
  },

  computed: {
    ...mapState(['facetGroups']),
    ...mapGetters(['groupedFacetIds', 'allFacets']),

    // a list of facets that have not yet been assigned to any facet group
    facets() {
      return this.allFacets.filter(f => !this.groupedFacetIds.includes(f.id));
    },
  },

  methods: {
    add() {
      const facetId = this.facetId;
      const cid = this.group.cid;

      this.$store.commit('addFacetToGroup', { cid, facetId });
    },
  },
};
</script>
