<template>
  <div
    class="facet facet--single"
    :class="{ 'facet--required': facet.required }"
  >
    <label v-text="facet.name"></label>
    <div class="dropdown-box" :class="{ selecting }">
      <div class="contents" v-on-clickaway="close">
        <div :class="classes" @click="selecting = !selecting">
          {{ selected }}
        </div>

        <ul class="options">
          <li
            v-for="choice in choices"
            @click="select(choice)"
            :key="choice.id"
            v-text="choice.value"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ActsAsFacet from './ActsAsFacet';
import ActsAsDropdown from 'mixins/ActsAsDropdown';

export default {
  mixins: [ActsAsFacet, ActsAsDropdown],

  mounted() {
    this.$nextTick(() => {
      const defaultChoice = this.choices.find(c => c.default);

      if (defaultChoice) {
        this.setChoices({
          facet_id: this.facet.id,
          choices: [defaultChoice.id],
        });
      }
    });
  },

  computed: {
    choices() {
      const choices = [...(this.facet.choices.data || [])];

      if (!this.facet.required) {
        choices.unshift({ value: 'Select', placeholder: true });
      }

      return choices;
    },

    selected() {
      const [choiceId] = this.getChoices(this.facet.id);

      if (choiceId) {
        const choice = this.choices.find(c => c.id === choiceId);

        if (choice) {
          return choice.value;
        }
      }

      return 'Select';
    },
  },

  methods: {
    select(choice) {
      this.selecting = false;

      const choices = choice.id ? [choice.id] : [];

      this.setChoices({ facet_id: this.facet.id, choices });
    },
  },
};
</script>
