<template>
  <div>
    <h3>Custom Message</h3>
    <media-card
      type="image"
      field-name="full_image"
      label="Image Mask for Message Area"
      :url="facet.full_image_url"
      :removable="false"
    >
      <template v-slot:help>
        Must be a transparent PNG file. Writing will be allowed wherever alpha
        channel data exists in the image.
      </template>
    </media-card>

    <facet-choices
      name-label="Color Display Name"
      title="Color Choices"
      :allow-images="false"
    >
      <template v-slot:additional-fields="{ choice, updateMeta }">
        <facet-choice-input
          label="Color Hex Code"
          @blur="value => updateMeta('color', value)"
          :value="choice.meta.color"
          :disabled="!isOriginal"
        >
        </facet-choice-input>
      </template>
    </facet-choices>
  </div>
</template>

<script>
import FacetChoices from '../FacetChoices';
import FacetChoiceInput from '../FacetChoiceInput';
import MediaCard from 'components/MediaCard.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['facet']),
    ...mapGetters(['isOriginal']),
  },
  components: {
    FacetChoices,
    FacetChoiceInput,
    MediaCard,
  },
};
</script>
