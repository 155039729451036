<template>
  <div v-if="(isOriginal && product.id) || suggestions.length > 0">
    <hr />

    <h3>Suggested Products</h3>

    <template v-if="isOriginal">
      <product-search
        :origin-type="origin.type"
        :origin-id="origin.id"
        :query-params="{ exclude }"
        v-on:error="setError"
        v-on:searching="setError()"
        v-on:selected="add"
      >
      </product-search>

      <div class="alert alert-danger" v-if="error" v-html="error"></div>
    </template>

    <table class="table table-striped" v-if="suggestions.length > 0">
      <thead>
        <tr>
          <th>Product</th>
          <th>UPC 13</th>
          <th v-if="isOriginal"></th>
        </tr>
      </thead>

      <tbody>
        <selected-product
          v-for="suggestion in suggestions"
          :product="suggestion"
          :key="suggestion.id"
          :origin-type="origin.type"
          :origin-id="origin.id"
          :allow-removal="isOriginal"
          v-on:remove="remove(suggestion)"
        ></selected-product>
      </tbody>
    </table>

    <input
      type="hidden"
      name="suggestions[]"
      v-for="suggestion in suggestions"
      :key="suggestion.id"
      :value="suggestion.id"
    />
  </div>
</template>

<script>
import ProductSearch from 'components/ProductSearch/Search';
import SelectedProduct from 'components/ProductSearch/SelectedProduct';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      error: false,
    };
  },

  computed: {
    ...mapState(['suggestions', 'product']),
    ...mapGetters(['isOriginal', 'origin']),

    // A list of product ids to exclude from the suggestions
    exclude() {
      return [this.product.id, ...this.suggestions.map(p => p.id)];
    },
  },

  methods: {
    ...mapMutations(['addSuggestion', 'removeSuggestion']),

    add(product) {
      this.addSuggestion({ product });
    },

    setError(error = null) {
      this.error = error;
    },

    remove(product) {
      this.removeSuggestion({ product });
    },
  },

  components: {
    ProductSearch,
    SelectedProduct,
  },
};
</script>
