<template>
  <div>
    <a v-if="!showForm" href="#" @click.prevent="toggleForm">Add a Schdeule</a>
    <div v-show="showForm" class="panel panel-primary top-buffer">
      <div class="panel-body">
        <div class="row">
          <div class="col-sm-6">
            <label class="control-label">Name</label>
            <input v-model="name" id="name" class="form-control form-group" />
            <p class="help-block">Required</p>
          </div>
          <div class="col-sm-6">
            <a
              href="#"
              @click.prevent="toggleForm"
              class="btn btn-danger btn-sm pull-right"
              >Remove</a
            >
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <label class="control-label">SRP Amount</label>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input v-model="srpAmount" class="form-control form-group" />
            </div>
          </div>

          <div class="col-sm-6">
            <label class="control-label">Sale SRP Amount</label>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input v-model="saleSrpAmount" class="form-control form-group" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label class="control-label">SRP Quantity</label>
            <input v-model="srpQuantity" class="form-control form-group" />
          </div>

          <div class="col-sm-6">
            <label class="control-label">Sale SRP Quantity</label>
            <input v-model="saleSrpQuantity" class="form-control form-group" />
          </div>
        </div>

        <div class="row top-buffer">
          <div class="col-sm-6">
            <label class="control-label">Start Date</label>
            <input
              class="form-control js-datepicker js-datepicker-start"
              id="price_schedule_starts_on"
              placeholder="Click to select"
            />
          </div>
          <div class="col-sm-6">
            <label class="control-label">End Date</label>
            <input
              class="form-control js-datepicker js-datepicker-end"
              id="price_schedule_ends_on"
              placeholder="Never End"
            />
          </div>
        </div>

        <div class="row top-buffer">
          <div class="col-sm-6">
            <select v-model="recurrenceType" class="form-control">
              <option value="never" selected>Never Recur</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>
        </div>

        <div class="top-buffer">
          <a @click.stop="addSchedule" class="btn btn-primary btn-sm"
            >Add Schdeule</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker from 'lib/DateTimePicker';
import $ from 'jquery';
import moment from 'moment';
import 'pickadate/lib/picker.date';
import 'pickadate/lib/picker.time';
import { select, el, asCents, token, capitalizeFirstLetter } from 'lib/helpers';

export default {
  mounted() {
    this.initalizeDatePickers();
    this.setupTokensForExistingRows();
    this.setupRemoveButtons();
  },

  data() {
    return {
      name: '',
      srpAmount: '0.00',
      saleSrpAmount: '0.00',
      srpQuantity: '1',
      saleSrpQuantity: '1',
      startDate: '',
      endDate: '',
      recurrenceType: 'never',
      currentGroupToken: '',
      showForm: false,
    };
  },

  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
      this.currentGroupToken = token();
    },

    addSchedule() {
      if (!this.name || this.startDate === '') {
        return;
      }

      const scheduleTable = $('#price-schedule-table');

      const newTableGroup = document.createElement('div');
      newTableGroup.classList.add('custom-table-group');

      const newTableRow = document.createElement('div');
      newTableRow.classList.add('custom-table-row');
      newTableRow.setAttribute(
        'id',
        `custom-table-row-${this.currentGroupToken}`
      );

      const rowTitle = document.createElement('span');
      rowTitle.innerText = `Name: ${this.name}`;

      const name = this.createHiddenInputWithValue('name', this.name);
      const srpAmount = this.createHiddenInputWithValue(
        'srp_amount',
        this.srpAmount,
        true
      );
      const saleSrpAmount = this.createHiddenInputWithValue(
        'sale_srp_amount',
        this.saleSrpAmount,
        true
      );
      const srpQuantity = this.createHiddenInputWithValue(
        'srp_quantity',
        this.srpQuantity
      );
      const saleSrpQuantity = this.createHiddenInputWithValue(
        'sale_srp_quantity',
        this.saleSrpQuantity
      );
      const startDate = this.createHiddenInputWithValue(
        'start_date',
        this.startDate
      );
      const endDate = this.createHiddenInputWithValue('end_date', this.endDate);
      const recurrenceType = this.createHiddenInputWithValue(
        'recurrence_type',
        this.recurrenceType
      );
      const deleteButton = document.createElement('a');
      deleteButton.classList.add('pull-right', 'bold', 'left-buffer');
      deleteButton.setAttribute('href', '#');
      deleteButton.innerText = 'Remove';
      deleteButton.addEventListener('click', event => {
        event.preventDefault();
        this.removeSchedule(event, newTableRow.getAttribute('id'));
      });

      const viewButton = document.createElement('a');
      viewButton.classList.add('pull-right', 'bold', 'js-price-schedule-show');
      viewButton.setAttribute('href', '#');
      viewButton.setAttribute('id', 'price-schedule-show');
      viewButton.innerText = 'View';
      viewButton.addEventListener('click', event => {
        event.preventDefault();
        newTableRow
          .querySelector('#schedule-details')
          .classList.toggle('schedule-details');
      });

      newTableRow.append(rowTitle, deleteButton, viewButton);
      this.createScheduleView(newTableRow);

      newTableGroup.append(
        newTableRow,
        name,
        srpAmount,
        saleSrpAmount,
        srpQuantity,
        saleSrpQuantity,
        startDate,
        endDate,
        recurrenceType
      );
      scheduleTable.append(newTableGroup);
      this.toggleForm();
    },

    removeSchedule(event, id) {
      event.preventDefault();

      const inputGroup = document.querySelectorAll('.js-custom-table-group');

      document.getElementById(id).parentElement.remove();
    },

    setupRemoveButtons() {
      const rows = document.querySelectorAll('.custom-table-row');

      rows.forEach(row => {
        row
          .querySelector('#price-schedule-remove')
          .addEventListener('click', event => {
            event.preventDefault();
            row.parentElement.remove();
          });

        row
          .querySelector('#price-schedule-show')
          .addEventListener('click', event => {
            event.preventDefault();
            row
              .querySelector('#schedule-details')
              .classList.toggle('schedule-details');
          });
      });
    },

    createHiddenInputWithValue(name, value, convertValueToCents = false) {
      const newInput = document.createElement('input');
      newInput.setAttribute('type', 'hidden');
      newInput.setAttribute(
        'name',
        `price_schedules[${this.currentGroupToken}][${name}]`
      );

      if (convertValueToCents) {
        newInput.value = asCents(value);
      } else {
        newInput.value = value;
      }

      return newInput;
    },

    setupTokensForExistingRows() {
      const inputGroup = document.querySelectorAll('.js-custom-table-group');

      inputGroup.forEach(group => {
        const groupToken = token();

        const inputs = group.querySelectorAll('input');
        const tableRows = group.querySelectorAll('custom-table-row');

        inputs.forEach(input => {
          const id = input.getAttribute('id');
          const name = input.getAttribute('name');

          input.setAttribute('id', id.replace('%CID%', groupToken));
          input.setAttribute('name', name.replace('%CID%', groupToken));
        });

        tableRows.forEach(input => {
          const id = input.getAttribute('id');

          input.setAttribute('id', id.replace('%CID%', groupToken));
        });
      });
    },

    createScheduleView(parentRow) {
      const container = document.createElement('div');
      container.setAttribute('id', 'schedule-details');
      container.classList.add('schedule-details');

      const srpAmountRow = document.createElement('div');
      srpAmountRow.innerText = `SRP Amount: $${this.srpAmount}`;

      const srpQuantityRow = document.createElement('div');
      srpQuantityRow.innerText = `SRP Quantity: ${this.srpQuantity}`;

      const saleSrpAmountRow = document.createElement('div');
      saleSrpAmountRow.innerText = `Sale SRP Amount: ${this.saleSrpAmount}`;

      const saleSrpQuantityRow = document.createElement('div');
      saleSrpQuantityRow.innerText = `Sale SRP Quantity: ${this.saleSrpQuantity}`;

      const startDateRow = document.createElement('div');
      startDateRow.innerText = `Start Date: ${new Date(
        this.startDate
      ).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}`;

      const endDateRow = document.createElement('div');
      endDateRow.innerText = `End Date: ${new Date(
        this.endDate
      ).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}`;

      const recurrenceTypeRow = document.createElement('div');
      recurrenceTypeRow.innerText = `Interval: ${capitalizeFirstLetter(
        this.recurrenceType
      )}`;

      container.append(
        srpAmountRow,
        srpQuantityRow,
        saleSrpAmountRow,
        saleSrpQuantityRow,
        startDateRow,
        recurrenceTypeRow
      );

      if (this.endDate !== '') {
        container.append(endDateRow);
      }

      parentRow.append(container);
    },

    getRawDate(which) {
      let date = $(`#price_schedule_${which}_on`).pickadate('get');

      date = date ? moment(date, 'MMMM D, YYYY').startOf('day') : false;

      return date ? date.utc().format('YYYY-MM-DD') : '';
    },

    initalizeDatePickers() {
      const pickadatePayload = {
        format: 'mmmm d, yyyy',
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true,
      };

      $('.js-datepicker.js-datepicker-start').pickadate({
        ...pickadatePayload,
        min: new Date(),
        onSet: ctx => {
          this.startDate = this.getRawDate('starts');
        },
      });

      $('.js-datepicker.js-datepicker-end').pickadate({
        ...pickadatePayload,
        min: new Date(),
        onSet: ctx => {
          this.endDate = this.getRawDate('ends');
        },
      });
    },
  },
};
</script>
