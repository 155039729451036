<template>
  <div id="bundle-manager">
    <div class="checkbox">
      <input type="hidden" value="0" name="bundle[save]" />
      <label>
        <input
          type="checkbox"
          value="1"
          name="bundle[save]"
          @input="active = !active"
          :checked="active"
        />
        This is the main product of a v-group of products
      </label>
      <p class="help-block">
        V-Groups allow you to group products whose only difference is a single
        attribute, such as &ldquo;size&rdquo;. Only the main product will appear
        in search results and product lists.
      </p>
    </div>

    <div class="panel panel-primary" v-if="isOpen">
      <div class="panel-body">
        <div class="row">
          <div class="col-xs-6">
            <div class="form-group">
              <label>V-Group Name</label>
              <input
                type="text"
                name="bundle[name]"
                class="form-control"
                :value="bundle.name"
                @input="updateBundleName($event.currentTarget.value)"
              />
            </div>
          </div>
          <div class="col-xs-6">
            <div class="form-group">
              <label>Main Attribute Value</label>
              <input
                type="text"
                name="bundle[key_name]"
                class="form-control input-sm"
                :value="mainMember.name"
                @input="updateMainAttributeValue($event.currentTarget.value)"
              />
            </div>
          </div>
        </div>

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Attribute Value</th>
              <th>Product</th>
              <th colspan="2">UPC 13</th>
            </tr>
          </thead>

          <tbody>
            <selected-product
              v-for="member in members"
              :key="member.product.id"
              :product="member.product"
              :origin-type="origin.type"
              :origin-id="origin.id"
              :allow-removal="member.cid !== mainMember.cid"
              v-on:remove="remove(member)"
            >
              <template v-slot:before>
                <td v-text="member.name"></td>
              </template>
            </selected-product>
          </tbody>
        </table>

        <h4>Add a V-Group Member</h4>

        <product-search
          v-if="!incoming.product.id"
          :origin-type="origin.type"
          :origin-id="origin.id"
          :query-params="searchParams"
          v-on:error="setError"
          v-on:searching="setError()"
          v-on:selected="setIncomingProduct"
        >
        </product-search>

        <template v-else>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Attribute Value</th>
                <th>Product</th>
                <th colspan="2">UPC 13</th>
              </tr>
            </thead>
            <tbody>
              <selected-product
                :product="incoming.product"
                :origin-type="origin.type"
                :origin-id="origin.id"
                v-on:remove="setIncomingProduct()"
              ></selected-product>
            </tbody>
          </table>

          <div class="form-group">
            <label>Attribute Value</label>
            <input
              type="text"
              class="form-control input-sm"
              v-model.trim="incoming.name"
            />
          </div>

          <button
            class="btn btn-sm btn-primary"
            :disabled="invalid_incoming"
            @click.stop.prevent="add(incoming)"
          >
            Add Member
          </button>
        </template>

        <div class="alert alert-danger" v-if="error" v-html="error"></div>
      </div>

      <input
        type="hidden"
        :name="`bundle[members][${index}][product_id]`"
        :value="member.product.id"
        v-for="(member, index) in members"
        :index="index"
        :key="`product--${index}`"
      />
      <input
        type="hidden"
        :name="`bundle[members][${index}][name]`"
        :value="member.name"
        v-for="(member, index) in members"
        :index="index"
        :key="`name--${index}`"
      />
    </div>
  </div>
</template>

<script>
import { get, pick } from 'lodash';
import qs from 'query-string';
import { token } from 'lib/helpers';
import { mapState, mapGetters } from 'vuex';
import ProductSearch from 'components/ProductSearch/Search';
import SelectedProduct from 'components/ProductSearch/SelectedProduct';

const PRODUCT_WHITELIST = ['id', 'upc_13', 'name', 'image_url'];

const stubIncoming = () => ({ product: {}, name: '' });

export default {
  mounted() {
    // The v-group should initially be expanded when the bundle exists or is
    // otherwise in some state of progress toward completion.
    this.$nextTick(
      () =>
        (this.active =
          this.bundle.id !== undefined ||
          this.bundle.name ||
          this.bundleMembers > 1)
    );
  },

  data() {
    return {
      error: '',
      active: false,
      incoming: stubIncoming(),
    };
  },

  computed: {
    ...mapState(['product', 'bundle', 'bundleMembers']),
    ...mapState({
      members: state =>
        state.bundleMembers.sort((a, b) =>
          a.product.id === state.product.id ? -1 : 1
        ),
    }),
    ...mapGetters(['origin']),

    isOpen() {
      return this.bundle.id || this.active;
    },

    mainMember() {
      return this.members.find(m => m.product.id === this.product.id) || {};
    },

    searchParams() {
      return {
        exclude: [
          this.product.id,
          ...this.members.map(m => m.product.id),
        ].filter(Number),
        restrict_to_origin: true,
        require_not_bundled: true,
      };
    },

    // is the incoming data valid?
    invalid_incoming() {
      if (!this.incoming.product.id) {
        return true;
      }

      if (!this.incoming.name.length) {
        return true;
      }

      if (this.members.map(m => m.name).includes(this.incoming.name)) {
        // it's a duplicate name!
        return true;
      }

      return false;
    },
  },

  methods: {
    setIncomingProduct(product = null) {
      if (product) {
        this.incoming.product = product;
      } else {
        this.incoming = stubIncoming();
      }
    },

    add({ name = '', product }) {
      this.$store.commit('addBundleMember', { name, product });

      this.setIncomingProduct();
    },

    setError(error) {
      this.error = error;
    },

    validate() {
      // ensure the attribute name doesn't already exist in the list of memebers
      if (this.members.map(m => m.name).includes(this.incoming.name)) {
        this.error = `Another member is already represented by that attribute value.`;

        return false;
      }

      // everything looks good!
      this.error = '';

      return true;
    },

    remove({ cid }) {
      this.$store.commit('removeBundleMember', { cid });
    },

    updateBundleName(name) {
      const bundle = {
        ...this.bundle,
        name,
      };

      this.$store.commit('setBundle', { bundle });
    },

    updateMainAttributeValue(name) {
      const cid = this.mainMember.cid;
      const attributes = { name };

      this.$store.commit('updateBundleMember', { cid, attributes });
    },
  },

  components: {
    ProductSearch,
    SelectedProduct,
  },
};
</script>
